import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllProfiles } from "services/profilesService";
import { useLocation, useSearchParams } from "react-router-dom";
import useDebounce from "./useDebounce";
import { profilesQueryKeys } from "settings/constants/queryKeys";

const useProfiles = () => {
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();
  const queryParams = new URLSearchParams(location.search);
  const [searchText, setSearchText] = useState("");
  const { debouncedValue, isLoading } = useDebounce(searchText, 500);

  const gigsQuery = useQuery({
    queryKey: profilesQueryKeys(debouncedValue),
    queryFn: () => getAllProfiles(searchText),
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const searchParam = queryParams.get("search");
    if (searchParam) {
      setSearchText(searchParam);
    }
  }, []);

  useEffect(() => {
    if (!!searchText) {
      queryParams.set("search", searchText);
    } else {
      queryParams.delete("search");
    }
    setSearchParams(queryParams);
  }, [searchText]);

  return {
    ...gigsQuery,
    searchText,
    searchLoading: isLoading,
    onSearch: (text: string) => setSearchText(text),
  };
};

export default useProfiles;
