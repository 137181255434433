import { useAuth } from "context/AuthProvider";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { ROUTES } from "settings/constants/routes";

const AuthPagesLayout = () => {
  const { user } = useAuth();
  const location = useLocation();

  if (!!user)
    return <Navigate to={ROUTES.ORDERS} state={{ from: location }} replace />;
  return <Outlet />;
};

export default AuthPagesLayout;
