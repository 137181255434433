import { Heading, Stack, Wrap, WrapItem } from "@chakra-ui/react";
import SpinnerLoading from "components/ui/Loaders/SpinnerLoading";
import useOrder from "hooks/useOrder";
import { useParams, useSearchParams } from "react-router-dom";
import OrderPageTabs from "./OrderPageTabs";
import Alert from "components/features/Alert";
import ChatSection from "./ChatSection";
import OrderDetailsSection from "./OrderDetailsSection";
import ActivitiesSection from "./ActivitiesSection";

const OrderPage = () => {
  const { id: orderId } = useParams();
  const { data: order, isLoading, isError } = useOrder(orderId);
  const [searchParams] = useSearchParams();
  const activeTab = searchParams.get("tab");

  if (!!isLoading) return <SpinnerLoading />;
  if (!order || !!isError)
    return (
      <Alert
        status="error"
        title="Order not found! Please try refresh or contact support"
      />
    );
  if (!orderId)
    return (
      <Alert status="error" title="Order not found! Please contact support" />
    );
  return (
    <Stack spacing={0}>
      <Heading textTransform="capitalize">Order</Heading>
      <OrderPageTabs />

      <Wrap
        justify="space-between"
        spacing={10}
        direction={{ base: "column", lg: "row" }}
        pr={{ base: 0, lg: 5 }} // Had to add padding on right side to avoid cards going inside of the wrapper
      >
        <WrapItem flex={1}>
          {activeTab === "chat" ? (
            <ChatSection orderId={orderId} />
          ) : (
            <ActivitiesSection
              orderStatus={order.status}
              orderId={orderId}
              activities={order.activities}
              remainingRevisions={order.maxRevisionsCount}
            />
          )}
        </WrapItem>
        <WrapItem w={{ base: "full", lg: "20rem" }}>
          <OrderDetailsSection
            order={{
              id: order.id,
              status: order.status,
              profile: order.profileId.title,
              delivery: order.deliveryAt || order.estDeliveryDate,
              totalAmount: order.totalAmount,
              maxRevisionsCount: order.maxRevisionsCount,
              activities: order.activities,
            }}
            offer={{
              id: order.offerId.id,
              title: order.offerId.title,
            }}
          />
        </WrapItem>
      </Wrap>
    </Stack>
  );
};

export default OrderPage;
