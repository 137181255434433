import axios from "libraries/axios";
import { API_URL } from "settings/site-credentials";

export async function fetchAllNotifications() {
  const response = await axios.get(`/notifications`);
  return response.data.data.notifications;
}

export async function markAsReadNotification(notificationId: string) {
  try {
    const { data } = await axios.patch(`/notifications/markAsRead`, {
      notificationIds: [notificationId],
    });
    return { data };
  } catch (error) {
    console.log({ error });
    return { error: "Something went wrong!" };
  }
}

export async function markNotificationsAsRead(ids: any[]) {
  try {
    const { data } = await axios.patch(`/notifications/markAsRead`, {
      notificationIds: ids,
    });
    return { data };
  } catch (error) {
    console.log({ error });
    return { error: "Something went wrong!" };
  }
}
export async function deleteNotifications(ids: string[]) {
  try {
    const { data } = await axios.delete(
      `${API_URL}/notifications?ids=${JSON.stringify(ids)}`
    );
    return { data };
  } catch (error) {
    console.log({ error });
    return { error: "Something went wrong!" };
  }
}
