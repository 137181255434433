import { Container, Flex, Stack } from "@chakra-ui/react";
import ContentSection from "./ContentSection";
import SpinnerLoading from "components/ui/Loaders/SpinnerLoading";
import Alert from "components/features/Alert";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import OfferPricingCard from "./OfferPricingCard";
import useOffer from "hooks/useOffer";
import SuccessModal from "pages/AccountPage/BillingPage/SuccessModal";

const OfferPage = () => {
  const params = useParams();
  const { data, isLoading, error } = useOffer(params.offerId);
  const [searchParams, setSearchParams] = useSearchParams();
  const isPaymentSuccess = searchParams.get("payment") === "success";
  const location = useLocation();

  if (!!isLoading)
    return (
      <Container>
        <SpinnerLoading />
      </Container>
    );

  if (!!error)
    return (
      <Container maxW="container.xl" centerContent>
        <Alert
          showCloseIcon={false}
          status="error"
          title="Something went wrong while fetching gigs."
          w="max"
        />
      </Container>
    );

  return (
    <Stack spacing={4} bg="white">
      <Container maxW="container.xl" my={10} px={{ base: 5, md: 10 }}>
        {data && (
          <Flex gap={10} direction={{ md: "row-reverse", base: "column" }}>
            <Stack width={{ md: "40rem", base: "full" }} spacing={4}>
              <OfferPricingCard gigId={data.id} />
            </Stack>
            <ContentSection data={data} />
          </Flex>
        )}
      </Container>

      <SuccessModal
        isOpen={isPaymentSuccess}
        description="You have successfully purchased the package."
        onClose={() => {
          const queryParams = new URLSearchParams(location.search);
          queryParams.delete("payment");
          setSearchParams(queryParams);
        }}
      />
    </Stack>
  );
};

export default OfferPage;
