import { FC, useState } from "react";
import {
  Avatar,
  Badge,
  Circle,
  Flex,
  HStack,
  Heading,
  Icon,
  Stack,
  Text,
  Wrap,
  useDisclosure,
} from "@chakra-ui/react";
import { BsChatSquareText } from "react-icons/bs";
import { formatDistanceToNow } from "date-fns";
import { OrderMessage } from "types/message.types";
import { FilePreview, ImageEditor } from "hr-design-system";
import { User } from "types/user.types";

interface Props {
  content: string;
  attachments: OrderMessage["attachments"];
  createdBy: Pick<User, "avatar" | "fullName" | "role">;
  createdAt: string;
  handleFileUpload: (files: File[] | File) => void;
}
const MessageItem: FC<Props> = ({
  content,
  attachments,
  createdBy,
  createdAt,
  handleFileUpload,
}) => {
  const [editFile, setEditFile] = useState<{
    secure_url?: string;
    name?: string;
  }>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <HStack align="start" spacing={{ base: 1, md: 4 }}>
      <Flex direction="column" alignItems="center" aria-hidden="true" mt={1}>
        {!createdBy.avatar ? (
          <Circle
            size={{ base: "10", md: "14" }}
            borderWidth="4px"
            borderColor={"white"}
            bg={"blue.500"}
            color={"white"}
            overflow="hidden"
            objectFit="cover"
          >
            <Icon as={BsChatSquareText} boxSize={{ base: "4", md: "5" }} />
          </Circle>
        ) : (
          <Avatar
            size={{ base: "sm", md: "md" }}
            src={createdBy.avatar}
            name={createdBy.fullName}
          />
        )}
      </Flex>
      <Stack spacing="4" pt="1" flex="1">
        <Flex direction="column">
          <HStack>
            <Heading
              fontSize="md"
              fontWeight="semibold"
              textTransform="capitalize"
            >
              {createdBy.fullName}
            </Heading>
            {createdBy.role === "talent" && (
              <Badge colorScheme="red">{"Talent"}</Badge>
            )}
          </HStack>
          <Text fontSize={{ base: "xs", md: "sm" }} color={"gray.500"}>
            {formatDistanceToNow(new Date(createdAt), {
              addSuffix: true,
              includeSeconds: true,
            })}
          </Text>
        </Flex>
        <Stack spacing={6} p="4" bg="gray.100" rounded="md" w="full">
          <Text fontSize={{ base: "sm", md: "md" }}>{content}</Text>
          {attachments.length > 0 && (
            <Wrap>
              {attachments.map((att) => {
                const file = {
                  thumbnailUrl: att.thumbnailUrl || att.cdnUrl,
                  secure_url: att.cdnUrl,
                  format: att.format,
                  name: att.name,
                };

                return (
                  <FilePreview
                    key={file.name}
                    size="md"
                    fileURL={file.secure_url}
                    fileType={file.format || ""}
                    fileName={file.name}
                    allowClickToEnlarge
                    allowDownload
                    allowEdit
                    onEdit={() => {
                      onOpen();
                      setEditFile(file);
                    }}
                  />
                );
              })}
            </Wrap>
          )}

          {!!isOpen && !!editFile?.secure_url && (
            <ImageEditor
              defaultUploadFile={{
                path: editFile.secure_url || "",
                name: editFile.name || "",
              }}
              wrapInModal={true}
              onUpload={async (file) => {
                const convertedFile = new File([file.blobFile], file.fileName, {
                  type: file.blobFile.type,
                });
                handleFileUpload(convertedFile);
                onClose();
              }}
              onModalClose={() => {
                onClose();
              }}
            />
          )}
        </Stack>
      </Stack>
    </HStack>
  );
};

export default MessageItem;
