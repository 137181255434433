/////////////////////
// App Page
/////////////////////

// Basic Imports

// Design Imports
import { Routes, Route, Navigate } from "react-router-dom";

// Layout and Section Imports
import {
  LoginPage,
  SignUpPage,
  ProjectPage,
  NewProjectPage,
  ProfilePage,
  BillingPage,
  SecurityPage,
  CommunicationsPage,
  HelpPage,
  ForgotPage,
  SuccessPage,
} from "./pages";
import {
  AllProjects,
  CompletedProjects,
  DraftProjects,
  PausedProjects,
  ActiveProjects,
} from "./pages/ProjectsPage";
import {
  AppLayout,
  AccountLayout,
  RequireAuthLayout,
  RequirePlanLayout,
  DashboardLayout,
  OrdersLayout,
} from "./components/layouts";
import { NewProjectPageById } from "pages/NewProjectPage";
import Custom404 from "pages/404";
import VerifyPage from "pages/AuthPage/VerifyPage";

// Data Imports

// Interfaces

// Functions

// Custom Hooks and Services
import ResetPasswordPage from "pages/AuthPage/ResetPasswordPage";
import SendVerificationEmail from "pages/AuthPage/SendVerificationEmail";
import AuthPagesLayout from "components/layouts/AuthPagesLayout";
import Practice from "pages/practice";
import OffersPage from "pages/OffersPage/OffersPage";
import OfferPage from "pages/OfferPage/OfferPage";
import ActiveOrdersPage from "pages/OrdersPage/ActiveOrdersPage";
import DeliveredOrdersPage from "pages/OrdersPage/DeliveredOrdersPage";
import CompletedOrdersPage from "pages/OrdersPage/CompletedOrdersPage";
import CancelledOrdersPage from "pages/OrdersPage/CancelledOrdersPage";
import PendingOrdersPage from "pages/OrdersPage/PendingOrdersPage";
import ProfilesPage from "pages/ProfilesPage";
import UserProfilePage from "pages/ProfilePage";
import OrderPage from "pages/OrderPage";
import ProjectsLayout from "components/layouts/ProjectsLayout";
import { ROUTES } from "settings/constants/routes";
import RevisionOrdersPage from "pages/OrdersPage/RevisionOrdersPage";

// Interfaces
export interface NavItemProps {
  label: string;
  href: string;
}

// Data
const DASHBOARD_NAV_ITEMS: NavItemProps[] = [{ label: "Overview", href: "/" }];

function App() {
  return (
    <Routes>
      <Route path="/practice" element={<Practice />} />
      <Route element={<AuthPagesLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignUpPage />} />
      </Route>
      <Route path="success/:token" element={<SuccessPage />} />
      <Route path="forgot" element={<ForgotPage />} />
      <Route path="account/verify/:token" element={<VerifyPage />} />
      <Route path="account/verify" element={<SendVerificationEmail />} />
      <Route
        path="account/password/reset/:token"
        element={<ResetPasswordPage />}
      />
      {/* Everything that inside the RequireAuth route requires an Authentication */}
      <Route element={<RequireAuthLayout />}>
        {/* Select Plan Page */}
        <Route element={<RequirePlanLayout />}>
          {/* <Route path="/" element={ <Navigate to="/projects" replace />}> */}
          <Route path="/" element={<AppLayout />}>
            {/* Dashboard Layout */}
            <Route
              element={
                <DashboardLayout
                  title="Client Dashboard"
                  navItems={DASHBOARD_NAV_ITEMS}
                />
              }
            >
              <Route path={"/"} element={<Navigate to="/orders" replace />} />
            </Route>
            {/* Projects Page Layout */}
            <Route element={<ProjectsLayout />}>
              <Route path={ROUTES.PROJECTS} element={<AllProjects />} />
              <Route
                path={ROUTES.PROJECTS_COMPLETED}
                element={<CompletedProjects />}
              />
              <Route path={ROUTES.PROJECTS_DRAFT} element={<DraftProjects />} />
              <Route
                path={ROUTES.PROJECTS_PAUSED}
                element={<PausedProjects />}
              />
              <Route
                path={ROUTES.PROJECTS_ACTIVE}
                element={<ActiveProjects />}
              />
            </Route>
            {/* Single Project Page */}
            <Route element={<DashboardLayout showNavigationHeader={false} />}>
              <Route path={ROUTES.PROJECT} element={<ProjectPage />} />
              <Route
                path={ROUTES.PROJECTS_WIZARD_ID}
                element={<NewProjectPageById />}
              />
            </Route>

            {/* New Project Page */}
            <Route
              element={
                <DashboardLayout
                  showNavigationHeader={false}
                  isProjectContainer
                />
              }
            >
              <Route path={ROUTES.PROJECTS_NEW} element={<NewProjectPage />} />
            </Route>

            {/* Gigs Pages */}
            <Route path={ROUTES.OFFERS} element={<OffersPage />} />
            <Route path={ROUTES.OFFER} element={<OfferPage />} />

            {/* Profiles Pages */}
            <Route path={ROUTES.PROFILES} element={<ProfilesPage />} />
            <Route path={ROUTES.PROFILE} element={<UserProfilePage />} />

            {/* Account Page */}
            {/* <Route path="account" element={<AccountPage />} /> */}
            {/* New Account Page */}
            <Route element={<AccountLayout />}>
              <Route path={ROUTES.ACCOUNT} element={<ProfilePage />} />
              <Route path={ROUTES.ACCOUNT_BILLING} element={<BillingPage />} />
              <Route
                path={ROUTES.ACCOUNT_SECURITY}
                element={<SecurityPage />}
              />
              <Route
                path={ROUTES.ACCOUNT_COMMUNICATIONS}
                element={<CommunicationsPage />}
              />
            </Route>

            {/* Orders page  */}
            <Route element={<OrdersLayout />}>
              <Route
                path={ROUTES.ORDERS_ACTIVE}
                element={<ActiveOrdersPage />}
              />
              <Route
                path={ROUTES.ORDERS_PENDING}
                element={<PendingOrdersPage />}
              />
              <Route
                path={ROUTES.ORDERS_DELIVERED}
                element={<DeliveredOrdersPage />}
              />
              <Route
                path={ROUTES.ORDERS_COMPLETED}
                element={<CompletedOrdersPage />}
              />
              <Route
                path={ROUTES.ORDERS_CANCELLED}
                element={<CancelledOrdersPage />}
              />
              <Route
                path={ROUTES.ORDERS_REVISION}
                element={<RevisionOrdersPage />}
              />
            </Route>

            {/* Single Order Page */}
            <Route element={<DashboardLayout showNavigationHeader={false} />}>
              <Route path={ROUTES.ORDER_ID} element={<OrderPage />} />
            </Route>

            {/* Help and Support Page */}
            <Route
              element={
                <DashboardLayout
                  showNavigationHeader={false}
                  isProjectContainer
                />
              }
            >
              <Route path={ROUTES.HELP} element={<HelpPage />} />
            </Route>

            <Route path="*" element={<Custom404 />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
