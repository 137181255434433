import axios from "libraries/axios";
import { Offer } from "types/offer.types";
import { Profile } from "types/profile.types";

export async function getAllProfiles(
  searchText: string = ""
): Promise<Profile[]> {
  const url = searchText
    ? `/profiles?search=${encodeURIComponent(searchText)}`
    : `/profiles`;

  const { data } = await axios.get(url);

  return data.data.profiles;
}

export type ProfileByIdResponse = { success: boolean; profile: Profile | null };
export async function getProfileById(id: string): Promise<ProfileByIdResponse> {
  try {
    const { data } = await axios.get(`/profiles/${id}`);

    return { success: true, profile: data.data.profile };
  } catch (error) {
    return { success: false, profile: null };
  }
}

//Get offers by profileId
export async function getOfferByProfileId(id: string): Promise<Offer[]> {
  const { data } = await axios.get(`/profiles/${id}/offers`);
  return data.data.offers;
}
