import { FC, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Square,
  Stack,
  Textarea,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { IoSend } from "react-icons/io5";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createOrderMessage } from "services/messageService";
import { orderMessagesKeys } from "settings/constants/queryKeys";
import PlusIcon from "icons/PlusIcon";
import { Dropzone, FilePreview, ImageEditor } from "hr-design-system";
import { Attachment } from "types/attachments.types";
import { UploadedFile } from "types/gallery.types";
import { BiEdit, BiPlus } from "react-icons/bi";

interface Props {
  orderId: string;
  uploadedFiles: UploadedFile[];
  handleRemoveAttachment: (fileId: string) => void;
  dropzoneDisclosure: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: unknown) => unknown;
    getDisclosureProps: (props?: unknown) => unknown;
  };
  handleFileUpload: (files: File[] | File) => void;
  handleSave: () => Promise<{
    deletedAttachments: Attachment[];
    updatedAttachments: Attachment[];
    uploadedAttachments: Attachment[];
    finalAttachments: string[];
  }>;
}
const SendMessageSection: FC<Props> = ({
  orderId,
  uploadedFiles,
  handleRemoveAttachment,
  dropzoneDisclosure,
  handleFileUpload,
  handleSave,
}) => {
  const [messageText, setMessageText] = useState("");
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const createMutation = useMutation({
    mutationFn: async () => {
      const { finalAttachments } = await handleSave();
      return createOrderMessage({
        resourceId: orderId,
        text: messageText,
        attachments: finalAttachments,
      });
    },
    onSuccess: () => {
      setMessageText("");
      queryClient.invalidateQueries({ queryKey: orderMessagesKeys(orderId) });
    },
  });

  return (
    <Stack>
      <FormControl>
        <FormLabel>Message</FormLabel>
        <Textarea
          value={messageText}
          onChange={(e) => setMessageText(e.currentTarget.value)}
          minH="8rem"
          placeholder="e.g., Could you please provide an update on the progress of the work?"
        />
      </FormControl>

      {/* Attachments */}
      <Wrap>
        {uploadedFiles.map((file) => (
          <Square key={file.id}>
            <FilePreview
              // width="full"
              // height="20"
              fileName={file.name}
              allowClickToEnlarge={true}
              fileURL={file.url}
              fileType={file.format}
              allowRemove={true}
              allowDownload={false}
              onRemove={() => handleRemoveAttachment(file.id)}
            />
          </Square>
        ))}
      </Wrap>

      <HStack justify="space-between">
        <Menu>
          <MenuButton aria-label="upload" rounded="full" type="button" w="full">
            <Box
              as="span"
              display="flex"
              rounded="full"
              justifyContent="center"
              alignItems="center"
              bg="blue.500"
              w={10}
              h={10}
            >
              <Icon
                as={PlusIcon}
                aria-label="Upload attachments"
                color="white"
              />
            </Box>
          </MenuButton>
          <MenuList>
            <MenuItem
              icon={<BiPlus size={20} />}
              onClick={() => {
                dropzoneDisclosure.onOpen();
              }}
            >
              Upload attachment
            </MenuItem>
            <MenuDivider />
            <MenuItem onClick={onOpen} icon={<BiEdit size={20} />}>
              Edit and download
            </MenuItem>
          </MenuList>
        </Menu>

        <IconButton
          rounded="md"
          aria-label="Send Message"
          icon={<IoSend />}
          isLoading={createMutation.isPending}
          onClick={() => createMutation.mutate()}
        />
      </HStack>

      {dropzoneDisclosure.isOpen && (
        <Dropzone
          acceptOnlyImages={false}
          wrapInModal={true}
          allowMultiple
          // hideUploadButton
          onUpload={(data) => console.log({ data })}
          onClose={dropzoneDisclosure.onClose}
          onFileUpload={handleFileUpload}
        />
      )}
      {isOpen && (
        <ImageEditor
          defaultUploadFile={{
            path: "",
            name: "",
          }}
          wrapInModal={true}
          onUpload={async (file) => {
            const convertedFile = new File([file.blobFile], file.fileName, {
              type: file.blobFile.type,
            });
            handleFileUpload(convertedFile);
            onClose();
          }}
          onModalClose={() => {
            onClose();
          }}
        />
      )}
    </Stack>
  );
};

export default SendMessageSection;
