import { FC, ReactNode } from "react";
import { SimpleGrid, Skeleton } from "@chakra-ui/react";
import useAttachmentsByIds from "hooks/useAttachmentByIds";
import { FilePreview } from "hr-design-system";

interface Props {
  attachmentIds: string[];
}
const ActivityAttachments: FC<Props> = ({ attachmentIds }) => {
  const { data: attachments, isLoading } = useAttachmentsByIds(attachmentIds);

  if (isLoading)
    return (
      <Layout>
        <Skeleton w="full" h="28" />
        <Skeleton w="full" h="28" />
        <Skeleton w="full" h="28" />
        <Skeleton w="full" h="28" />
      </Layout>
    );
  if (!attachments) return null;
  return (
    <Layout>
      {attachments.map((attachment) => {
        if (!attachment) return null;
        return (
          <FilePreview
            styles={{ width: "full", height: "28" }}
            key={attachment.id}
            fileName={attachment.name}
            allowClickToEnlarge={true}
            fileURL={attachment.cdnUrl}
            fileType={attachment.format}
            allowRemove={false}
            allowDownload={true}
          />
        );
      })}
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} spacing={2} w="full">
      {children}
    </SimpleGrid>
  );
};

export default ActivityAttachments;
