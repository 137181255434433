/////////////////////
// Require Auth Layout
// Anything with this layout require login.
/////////////////////

// Basic Imports
import Loader from "components/ui/common/Loader";
import { useAuth } from "context/AuthProvider";
import useCustomToast from "hooks/useCustomToast";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTES } from "settings/constants/routes";

// Design Imports

// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
const RequireAuthLayout = () => {
  const { user, isLoading: authLoading } = useAuth();
  const navigate = useNavigate();
  const { errorToast } = useCustomToast({ duration: 3000 });

  useEffect(() => {
    if (!authLoading && !user) {
      errorToast({
        title: "Authentication failed.",
        description: "Redirecting you to the login page...",
      });
      setTimeout(() => {
        navigate(ROUTES.LOGIN_IN);
      }, 3000);
    }
  }, [user, authLoading]);

  if (!!user) return <Outlet />;
  return <Loader />;
};

export default RequireAuthLayout;
