import { FC } from "react";
import { Divider, Stack } from "@chakra-ui/react";
import { Order, OrderActivity, OrderStatus } from "types/order.types";
import Alert from "components/features/Alert";
import DeliveryActivity from "./DeliveryActivity";
import RenderActivity from "./RenderActivity";
import SellerReviewActivity from "./SellerReviewActivity";
import OrderRevisionAction from "../ActionSection/OrderRevisionAction";
import RevisionActivity from "./RevisionActivity";
import BuyerReviewActivity from "./BuyerReviewActivity";
import OrderActionCard from "../ActionSection/OrderActionCard";
import useOrderActions from "hooks/useOrderActions";
import OrderProgressAlert from "./OrderAlerts/OrderProgressAlert";
import OrderActivityCard from "./OrderActivityCard";
import OrderReviewAction from "../ActionSection/OrderReviewAction";
import OrderDeliveryAction from "../ActionSection/OrderDeliveryAction";
import ActivityAccordion from "./RenderActivity/ActivityAccordion";
import OrderFinalDeliveryAction from "../ActionSection/OrderFinalDeliveryAction";

interface Props {
  orderId: string;
  orderStatus: OrderStatus;
  activities: Order["activities"];
  remainingRevisions: number | undefined;
}

const ActivitiesSection: FC<Props> = ({
  orderStatus,
  orderId,
  activities,
  remainingRevisions,
}) => {
  const {
    shouldShowProgressAlert,
    shouldShowRevisionAction,
    shouldShowSellerReviewForm,
    shouldShowRevisionAlert,
    shouldShowDeliveredAlert,
    shouldShowFinalDeliveryAction,
  } = useOrderActions(activities, orderStatus);

  if (activities.length === 0) {
    return (
      <Alert
        status="error"
        title="Activities not found!"
        description="This order doesn't have any activities to show."
      />
    );
  }

  return (
    <Stack w="full" spacing={6}>
      <OrderActionCard>
        {shouldShowProgressAlert && <OrderProgressAlert />}
        {shouldShowRevisionAlert && <OrderProgressAlert />}
        {shouldShowDeliveredAlert && <OrderDeliveryAction orderId={orderId} />}
        {shouldShowRevisionAction && (
          <OrderRevisionAction
            orderId={orderId}
            remainingRevisions={remainingRevisions}
          />
        )}
        {shouldShowSellerReviewForm && <OrderReviewAction orderId={orderId} />}
        {shouldShowFinalDeliveryAction && (
          <OrderFinalDeliveryAction orderId={orderId} />
        )}
      </OrderActionCard>
      <OrderActivityCard>
        <Stack divider={<Divider />} spacing={4}>
          {activities.map((activity, index) => {
            const isLast = index === activities.length - 1; // Check if this is the last activity
            return renderActivity(activity, orderId, isLast);
          })}
        </Stack>
      </OrderActivityCard>
    </Stack>
  );
};

const renderActivity = (
  activity: OrderActivity,
  orderId: string,
  isLast: boolean
) => {
  switch (activity.action) {
    case "delivery_created":
      return (
        <ActivityAccordion activity={activity} defaultOpen={isLast}>
          <DeliveryActivity
            key={activity._id}
            orderId={orderId}
            resourceId={activity.resourceId}
          />
        </ActivityAccordion>
      );
    case "order_started":
    case "order_completed":
      return <RenderActivity key={activity._id} activity={activity} />;
    case "customer_ratings":
      return (
        <ActivityAccordion activity={activity} defaultOpen={isLast}>
          <BuyerReviewActivity key={activity._id} orderId={orderId} />
        </ActivityAccordion>
      );
    case "talent_ratings":
      return (
        <ActivityAccordion activity={activity} defaultOpen={isLast}>
          <SellerReviewActivity key={activity._id} orderId={orderId} />
        </ActivityAccordion>
      );
    case "revision_requested":
      return (
        <ActivityAccordion activity={activity} defaultOpen={isLast}>
          <RevisionActivity
            key={activity._id}
            resourceId={activity.resourceId}
            orderId={orderId}
          />
        </ActivityAccordion>
      );
    default:
      return <RenderActivity key={activity._id} activity={activity} />;
  }
};
export default ActivitiesSection;
