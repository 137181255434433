/////////////////////
// Use Auth Form
/////////////////////

// Basic Imports
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBreakpointValue } from "@chakra-ui/react";

// Custom Hooks and Services
import { useAuth } from "context/AuthProvider";

import {
  forgotPassword,
  resetPassword,
  sendVerificationEmail,
  signup,
} from "services/authService";

import useCustomToast from "hooks/useCustomToast";
import { useMutation } from "@tanstack/react-query";
import { CustomError } from "libraries/axios";

// Interfaces
export type SignUpUserTypes = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  isLoading: boolean;
  error: string;
};
export interface LoginFormTypes {
  email: string;
  password: string;
}

export interface SignupFormTypes {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}
export interface ForgotFormTypes {
  email: string;
}
export interface ResetFormTypes {
  token: string;
  password: string;
  confirmPassword: string;
}

// Functions
const useAuthForm = () => {
  const { login } = useAuth();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { errorToast } = useCustomToast();
  const navigate = useNavigate();
  const [hasUserSignedUp, setHasUserSignedUp] = useState(false);
  const [isUserVerified] = useState(false);
  const [isEmailSentForReset, setIsEmailSentForReset] = useState(false);
  const loginMutation = useMutation({
    mutationFn: async (data: LoginFormTypes) =>
      login(data.email, data.password),
    onSuccess: () => navigate("/"), // TODO: Add onboarding user functionality here (if user don't have billing then redirecting user to offers page otherwise redirect user to projects page (if no project then redirect to project wizard otherwise redirect to projects))
    onError: (error) =>
      errorToast({
        title: "Authentication Failed.",
        description: error.message,
      }),
  });

  /***
   * Form handlers
   */
  const signupMutation = useMutation({
    mutationFn: async (formData: SignupFormTypes) => {
      if (formData.password !== formData.confirmPassword) {
        errorToast({ title: "Confirm password doesn't match" });
        return undefined;
      }

      return signup({
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        confirmPassword: formData.confirmPassword,
      });
    },
    onError: (error: CustomError) => {
      if (error.statusCode === 409) {
        errorToast({
          title: "Authentication failed.",
          description: error.message,
        });
      }
    },
  });

  const sendVerificationEmailMutation = useMutation<any, CustomError, string>({
    mutationFn: (email: string) => sendVerificationEmail(email),
  });

  const resetPasswordMutation = useMutation({
    mutationFn: (formData: ResetFormTypes) => {
      if (formData.password !== formData.confirmPassword) {
        errorToast({ title: "Confirm password doesn't match!" });
        throw new Error("");
      }

      return resetPassword({
        token: formData.token,
        newPassword: formData.password,
        confirmPassword: formData.confirmPassword,
      });
    },
  });

  const forgotPasswordMutation = useMutation({
    mutationFn: (formData: ForgotFormTypes) =>
      forgotPassword({ email: formData.email }),
  });

  return {
    isMobile,
    hasUserSignedUp,
    setHasUserSignedUp,
    isEmailSentForReset,
    setIsEmailSentForReset,
    signupMutation,
    loginMutation,
    forgotPasswordMutation,
    resetPasswordMutation,
    sendVerificationEmailMutation,
    isUserVerified,
  };
};

export default useAuthForm;
