import { Card, CardBody, Stack } from "@chakra-ui/react";
import { FC } from "react";
import ListMessages from "./ListMessages";
import SendMessageSection from "./SendMessageSection";
import useGalleryForm from "hooks/useGalleryForm";

interface Props {
  orderId: string;
}
const ChatSection: FC<Props> = ({ orderId }) => {
  const {
    uploadedFiles,
    handleRemoveAttachment,
    dropzoneDisclosure,
    handleFileUpload,
    handleSave,
  } = useGalleryForm({});
  return (
    <Card variant="outline" w="full">
      <CardBody>
        <Stack spacing={{ base: 2, md: 4 }}>
          <ListMessages orderId={orderId} handleFileUpload={handleFileUpload} />

          <SendMessageSection
            orderId={orderId}
            uploadedFiles={uploadedFiles}
            handleRemoveAttachment={handleRemoveAttachment}
            dropzoneDisclosure={dropzoneDisclosure}
            handleFileUpload={handleFileUpload}
            handleSave={handleSave}
          />
        </Stack>
      </CardBody>
    </Card>
  );
};

export default ChatSection;
