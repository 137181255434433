import {
  Text,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  useDisclosure,
  HStack,
  Divider,
  Spinner,
} from "@chakra-ui/react";
// import { INotificationDataState } from "hooks/useSocket";
// import { markNotificationsAsRead } from "services/notificationService";
import { Notification } from "./Notification";
import { NotificationBody } from "./NotificationBody";
import { IoNotificationsOffOutline } from "react-icons/io5";
import useNotifications from "hooks/useNotifications";
import { useAuth } from "context/AuthProvider";

const NotificationMenu = () => {
  const {
    notifications,
    markNotificationsAsRead,
    isLoading,
    deleteNotifications,
  } = useNotifications();
  const { user, isLoading: userLoading } = useAuth();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleClearAllNotifications = async (justMark: boolean) => {
    if (!notifications || notifications.length === 0) return;

    const allIds = notifications.map((not) => not.id) || [];

    if (allIds.length === 0) return;

    if (justMark) await markNotificationsAsRead(allIds);
    else deleteNotifications(allIds);

    onClose();
  };

  const unRead = notifications?.filter((notification) => !notification.isRead);

  const renderNotificationContent = () => {
    if (isLoading || userLoading) {
      return (
        <Stack w="full" h="full" alignItems="center" justifyContent="center">
          <Spinner size="xl" />
        </Stack>
      );
    }

    if (notifications && notifications.length === 0) {
      return (
        <Stack w="full" h="full" alignItems="center" justifyContent="center">
          <Icon as={IoNotificationsOffOutline} fontSize="4xl" color="gray" />
          <Text color="gray.500" fontSize="sm">
            Nothing to show
          </Text>
        </Stack>
      );
    }

    // Filter notifications to exclude those where userId matches the current user
    const filteredNotifications = notifications?.filter((notification) =>
      notification.recipients.includes(user?.id || "")
    );

    return (
      <>
        <NotificationBody
          data={filteredNotifications || []}
          userId={user?.id || ""}
          onDeleteNotification={deleteNotifications}
          onClickNotification={async ({ notificationId, isRead }) => {
            if (!!isRead) {
              onClose();
              return;
            }

            onClose();
            await markNotificationsAsRead([notificationId]);
          }}
        />

        <HStack
          position="absolute"
          bottom="0.4"
          borderTop="1px solid"
          borderColor="gray.200"
          w="full"
          bg="white"
          spacing={0}
          divider={<Divider orientation="vertical" h="30px" />}
        >
          <Button
            size={{ base: "sm", md: "md" }}
            variant="ghost"
            colorScheme="blue"
            w="full"
            onClick={() => handleClearAllNotifications(false)}
          >
            Clear all
          </Button>
          <Button
            size={{ base: "sm", md: "md" }}
            variant="ghost"
            colorScheme="blue"
            w="full"
            onClick={() => handleClearAllNotifications(true)}
          >
            Mark all read
          </Button>
        </HStack>
      </>
    );
  };

  return (
    <Box>
      <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnBlur>
        <PopoverTrigger>
          <Box>
            {/* FOR SOCKET IO */}
            <Notification count={unRead?.length || 0} />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          mr={4}
          outline="none"
          _focus={{
            outline: "none",
          }}
          w={{ base: "19rem", md: "22rem" }}
        >
          <PopoverArrow ml={2} />
          <PopoverHeader>
            <Flex justify="space-between" alignItems="center" my={1}>
              <Heading flex="1" justifySelf="flex-start" as="h4" size="sm">
                Notifications
              </Heading>
              <Box>
                <PopoverCloseButton size="md" color="gray.600" rounded="full" />
              </Box>
            </Flex>
          </PopoverHeader>
          <PopoverBody
            overflowY="auto"
            h={{ base: "sm", md: "md" }}
            color="black"
            p={0}
          >
            {renderNotificationContent()}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default NotificationMenu;
